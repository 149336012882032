* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background-color: var(--color-white);
}


a {
    color: unset;
    text-decoration: none;

}

.navbar__bg {
    background-color: var(--color-theme);
}

.slide-right {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-1-6 14:58:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
}

@keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
}


.section__padding {
    padding: 6.4rem 9.6rem;
}

.section__margin {
    padding: 6.4rem 9.6rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mt-1 {
    margin-top: 1rem
}

.mt-2 {
    margin-top: 2rem
}

.mt-3 {
    margin-top: 3rem;
}

.mt-6 {
    margin-top: 6rem;
}

.mb-2 {
    margin-bottom: 2rem
}

.mb-5 {
    margin-bottom: 5rem
}

.w-all {
    width: 100% !important;
}

.w-unset {
    width: unset !important;
}

.h-unset {
    height: unset !important;
}

.w-72p {
    width: 72% !important;
}

.w-50p {
    width: 50% !important;
}

.w-50r {
    width: 50rem !important;
}

.mb-half {
    margin-bottom: .5rem
}

.w-40 {
    width: 40rem !important;
}

.w-42r {
    width: 42rem !important;
}


.w-15r {
    width: 15rem !important;
}

.w-20r {
    width: 20rem !important;
}

.w-92p {
    width: 90% !important;
}


.w-65vw {
    width: 65vw !important;
}

.w-99p {
    width: 99% !important;
}

.pointer {
    cursor: pointer;
}

.sm-only-flex {
    display: none !important;
}

.color-grey {
    color: #999999 !important;
}

.color-black {
    color: black !important;
}

textarea {
    resize: none;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-flex-start {
    justify-content: flex-start !important;
}

.border-price {
    border: 1px solid #E1E4E8;
    border-right: white;
    border-top: white;
    border-bottom: white;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
}

.alpha_loader_style {
    display: flex;
    width: 100%;
    justify-content: center;
}

@media screen and (max-width: 980px) {

    .w-40 {
        width: 20rem !important;
    }

    .md-mt-2 {
        margin-top: 2rem
    }

    .md-mb-2 {
        margin-bottom: 2rem
    }

    .md-none {
        display: none !important;
    }

    .md-only-flex {
        display: flex !important;
    }

}

@media screen and (max-width: 600px) {

    .w-40 {
        width: 20rem !important;
    }

    .sm-mt-2 {
        margin-top: 2rem
    }

    .sm-mb-2 {
        margin-bottom: 2rem
    }

    .sm-none {
        display: none !important;
    }

    .sm-only-flex {
        display: flex !important;
    }

}