.payment-page-payment-container {
    width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.payment-page-text {

}

.payment-page-text > h2 {
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 600;    
}

.payment-page-payment-method-container {

}

.payment-page-choice {
    margin-top: 2rem;
    display: flex;
    width: 52rem;
    align-items: center;
}

.payment-page-choice > img {
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.payment-choice-contet {
    display: flex;
    align-items: center;
}

.payment-choice-contet > img {
    width: 30px;
    margin-right: 5px;
    cursor: pointer;
}

.payment-choice-contet > h3 {
    font-family: Open Sans;
    font-size: 18px;
}

.payment-page-cvv-expiry {
    display: flex;
}

.payment-page-cvv-expiry > .text-input > input {
    width: 25rem;
    margin-right: 1rem;
}

@media screen and (max-width: 980px) {

    .payment-page-payment-container {
        width: unset;
    }

    .payment-page-choice {
        width: 30rem;
    }

    .payment-page-cvv-expiry {
        flex-direction: column;
    }

    .payment-page-master-card-input-container > .text-input > input {
        width: 30rem !important;
    }

    .payment-page-cvv-expiry > .text-input > input {
        width: 30rem;
        margin-right: 0px;
    }

}

@media screen and (max-width: 600px) {

    .payment-page-payment-container {
        width: unset;
    }

    .payment-page-choice {
        width: 30rem;
    }

    .payment-page-cvv-expiry {
        flex-direction: column;
    }

    .payment-page-master-card-input-container > .text-input > input {
        width: 30rem !important;
    }

    .payment-page-cvv-expiry > .text-input > input {
        width: 30rem;
        margin-right: 0px;
    }

}
