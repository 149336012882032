.booking-done-page-check-container {
    width: 40rem;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.booking-done-page-check-container > h2 {
    margin-top: 2rem;
    text-align: center;
    font-family: Open Sans;
    font-size: 26px;
    font-weight: 600;
}

.booking-done-page-check-container > img {
    width: 100px;
}


.booking-done-page-book-now-button-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 200px;
    width: 100%;
    margin-bottom: 5rem;
}

.booking-done-page-check-container {
    
}