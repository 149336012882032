.register-page-register-container {
    display: flex;
    height: 130vh;
    justify-content: center;
    align-items: center;
}

.register-page-register-logo-container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #376FCC;
}

.register-page-register-logo-container > p {
    font-family: Open Sans;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: white;
    width: 30rem;
}

.register-page-register-logo {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.register-page-register-logo > p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: white
}

.register-page-register-logo > img {
    margin-right: 1.5rem;
    width: 100px
}

.register-page-register-form-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.separator {
    height: 1px;
    width: 72%;
    background-color: black;
}
.social-button {
    display: flex;
    justify-content: space-between;
    width: 72%;
}

.register-page-register-form-container > h1 {
    align-self: flex-start;
    margin-left: 10rem;
    margin-bottom: 5rem;
    margin-top: 2rem;
}

.social-login-container {
    display: flex;
    justify-content: center;
    width: 75%;
}

.register-input-container {
    width: 72% !important;
}

.register-input {
    width: 100% !important;
}

.social-login-item {
    align-items: center;
    background-color: #f1f1f1;
    border: none;
    border-radius: 5px;
    display: flex;
    height: 5rem;
    justify-content: center;
    margin: 0.5rem;
    padding: 0.5rem;
    width: 100%;
}

.social-login-item > img {
    width: 30px;
    height: auto;
    margin-right: 1rem;
}

.color-blue {
    color: #376FCC !important;
}
.forgot-password-text {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
}
.forget-description {
    font-size: 18px;
    font-weight: 400;
    color: #777777;
    margin-bottom: 20px;
    line-height: 30px;
    margin-left: 20px;
  }

.have-an-account-prompt {
    width: 72%;
    margin-bottom: 2rem;
}

.forgot-password {
    display: flex;
    justify-content: space-between;
    width: 70%;
}
.resend-code {
    font-size: 16px;
    font-weight: 600;
    font-family: Open Sans;
    line-height: 24px;
    color: #7F7F7F;
    text-align: center;
    margin-top: 20px;
}
.have-an-account-prompt > span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: grey;
    margin-right: 2px;
}

.continue-as-guest {
    margin-top: 2rem;
    margin-bottom: 5rem;
}

.continue-as-guest > span {
    font-family: Inter;
    font-size: 14px;
    font-weight: bold;
    color: grey;
    margin-right: 2px;
    margin-bottom: 2rem;
}

@media screen and (max-width: 980px) {
    
    .register-page-register-container {
        flex-direction: column;
        height: unset !important;
    }

    .register-page-register-form-container {
        margin-top: 20px;
        height: unset !important;
        width: 100%;
    }

    .navbar-login {
        margin-left: 0.5rem;
    }

    .register-page-register-logo-container {
        width: 100%;
        height: 25vh;
        padding-top: 2rem;
    }
    
    .register-page-register-logo-container > p {
        display: none;
    }
    
    .register-page-register-logo {
        margin-bottom: 0px;
    }
    
    .register-page-register-logo > p {

    }
    
    .register-page-register-logo > img {
        
    }
    
    .register-page-register-form-container > .text-input > input {
        width: 50rem;
    }

    .register-page-register-form-container > .choice-input > .choice-input-clickable {
        width: 50rem !important;
    }

    .register-page-register-form-container > .button-container {
        width: 50rem !important;
    }

    .text-container {
        justify-content: flex-start !important;
    }

    .text-container > h1 {
        width: 50rem !important;
        justify-content: flex-start !important;
    }

    .register-page-register-form-container > h1 {
        margin-bottom: 2rem;
        margin-top: 2rem;
        margin-left: 13rem;
        /* align-self: center; */
    }
    
    .social-login-container {
        flex-direction: column;
    }

    .text-container {
        margin-top: 5rem;
    }

    .social-login-item {
        width: unset;
    }
    
    .social-login-item > img {
        
    }
    
    .color-blue {

    }
    
    .have-an-account-prompt {
        width: unset;
        margin-bottom: 1rem;
    }
    
    .have-an-account-prompt > span {
        
    }
    
    .continue-as-guest {
        width: unset;
        margin-bottom: 1rem;
    }
    
    .continue-as-guest > span {
        
    }
    

}

@media screen and (max-width: 600px) {
    
    .register-page-register-container {
        flex-direction: column;
        height: unset;
    }

    .register-page-register-form-container {
        margin-top: 20px;
        height: unset !important;
        width: 100%;
    }

    .navbar-login {
        margin-left: 0.5rem;
    }

    .register-page-register-logo-container {
        width: 100%;
        height: 20vh;
        padding-top: 2rem;
    }
    
    .register-page-register-logo-container > p {
        display: none;
    }
    
    .register-page-register-logo {
        margin-bottom: 0px;
    }
    
    .register-page-register-logo > p {

    }
    
    .register-page-register-logo > img {
        
    }
    
    .register-page-register-form-container > .text-input > input {
        width: 30rem;
    }

    .register-page-register-form-container > .choice-input > .choice-input-clickable {
        width: 30rem !important;
    }

    .register-page-register-form-container > .button-container {
        width: 25rem !important;
    }

    .text-container {
        justify-content: flex-start !important;
    }

    .text-container > h1 {
        width: 30rem !important;
        justify-content: flex-start !important;
    }

    .register-page-register-form-container > h1 {
        margin-bottom: 2rem;
        margin-top: 2rem;
        margin-left: 5rem;
        /* align-self: center; */
    }
    
    .social-login-container {
        flex-direction: column;
    }

    .text-container {
        margin-top: 5rem;
    }

    .social-login-item {
        width: unset;
    }
    
    .social-login-item > img {
        
    }
    
    .color-blue {

    }
    
    .have-an-account-prompt {
        width: unset;
        margin-bottom: 1rem;
    }
    
    .have-an-account-prompt > span {
        
    }
    
    .continue-as-guest {
        width: unset;
        margin-bottom: 1rem;
    }
    
    .continue-as-guest > span {
        
    }
    

}
