.alpha-side_bar_top_container {
  display: flex;
  flex-direction: column;
  width: 28rem;
  height: 100vh;
  background-color: #EFEFEF;
  position: relative;
  z-index: 999px;
}

.alpha-side_bar-logo_container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.alpha-side_bar-logo_container img {
  width: 13.5rem;
  height: 6.3rem;
  object-fit: cover;
}

.alpha-side_bar-items_top_container {
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  padding-left: 2rem;
  flex: 1;
}

.alpha-side_bar-items_top_container h5 {
  font-family: lato;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
  padding-bottom: 2rem;
  color: #767582;
  cursor: pointer;
}

.alpha-side_bar-items_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.6rem;

}

.alpha-side_bar-items_container img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  cursor: pointer;
}

.alpha-side_bar-items_title_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.alpha-side_bar-items_container h2 {
  font-family: lato;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
  padding-left: 1.6rem;
  color: #767582;
  cursor: pointer;
}

.alpha-side_bar_bottom_items_view {
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
}

.alpha-side_bar-divider {
  display: flex;
  margin-right: 2.7rem;
  margin-left: 2.7rem;
  border-bottom: 2px solid #E6E6E6;
  margin-bottom: 2.6rem;
  margin-top: -1rem;
}

.alpha-side_bar-dropdown_items_div {
  padding-left: 4rem;
}

.alpha_side_bar_rotate {
  transform: rotate(180deg);
}

.alpha-side_bar_cross_view {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: -3rem;
}

.alpha-side_bar_cross_view img {

  width: 3rem;
  height: 3rem;
  object-fit: cover;
  cursor: pointer;
}

@media screen and (max-width:1300px) {
  .alpha-side_bar_top_container {
    display: none
  }
}