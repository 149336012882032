.password-input-container {
    background-color: #F1F1F1;
    display: flex;
    align-items: center;
    width: 40rem;
}

.password-input-container > input {
    width: 37rem;
}

.password-input-container > img {
    width: 20px;
    height: auto;
}

@media screen and (max-width: 600px) {

    .password-input-container {
        width: unset;
    }

    .password-input-container > input {
        width: unset;
    }

    .password-input-container > img {
        margin-left: -20px;
    }

}