.alpha-my_shop-search_top_view_two {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 38.5rem;
  height: 3.8rem;
  margin-left: -15rem;
}

.alpha-my_shop-search_filter_view_two {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 8.8rem;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #F18805;
  cursor: pointer;
}

.alpha-my_shop-search_filter_view_two h2 {
  font-family: lato;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5rem;
  text-align: center;
  color: #FFFFFF;
}

.alpha-my_shop-search_filter_view_two img {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 2.3rem;
}

.alpha-my_shop-search_filter_dropdown_view_two {
  width: 8.8rem;
  max-height: 10rem;
  z-index: 99;
  background-color: #F18805;
  position: absolute;
  top: 3.5rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 1rem 2rem;
  overflow: scroll;
}

.alpha-my_shop-search_filter_dropdown_view_two p {
  font-family: lato;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.3rem;
  color: #FFFFFF;
  padding-bottom: 1rem;
}

.alpha-my_shop-search_search_view_two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
  height: 100%;
  border-top: 1px solid #9C9C9C;
  border-bottom: 1px solid #9C9C9C;
  border-right: 1px solid #9C9C9C;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #ffffff;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.alpha-my_shop-search_search_view_two img {
  height: 2rem;
  width: 2rem;
  margin-left: 1rem;
}

.alpha-my_shop-search_search_view_two input {
  flex: 1;
  border: none;
  outline: none;
  font-family: lato;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: black;
}

@media screen and (max-width:850px) {
  .alpha-my_shop-search_top_view_two {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

@media screen and (max-width:734px) {
  .alpha-my_shop-search_top_view_two {
    margin-right: 2rem;
  }
}

@media screen and (max-width:673px) {
  .alpha-my_shop-search_top_view_two {
    margin-bottom: 2rem;
    margin-left: 2rem;
  }

  .alpha-my_shop-search_search_view_two input {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .alpha-my_shop-search_search_view_two img {
    height: 1.8rem;
    width: 1.8rem;
  }

  .alpha-my_shop-search_top_view_two {
    width: 38.5rem;
    height: 3.6rem;
  }

  .alpha-my_shop-search_filter_view_two {
    width: 6.8rem;
    height: 100%;
  }

  .alpha-my_shop-search_filter_view_two h2 {
    font-size: 1.4rem;
    line-height: 1.23rem;
  }

  .alpha-my_shop-search_filter_view_two img {
    margin-left: 1.3rem;
  }

  .alpha-my_shop-search_filter_dropdown_view_two {
    width: 6.8rem;
    top: 3.4rem;
    padding: 1rem 0rem 1rem 1rem;
  }

  .alpha-my_shop-search_filter_dropdown_view_two p {
    font-size: 1.2rem;
    line-height: 1.1rem;
  }
}

@media screen and (max-width:370px) {
  .alpha-my_shop-search_search_view_two input {
    width: 10rem;
    flex: 0;
  }
}