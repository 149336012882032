html {
  font-family: 'Inter'
}

.navbar {
  margin-left: 9rem;
  margin-right: 9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sticky-navbar-container {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.sticky-navbar-container.opened {
  background: white;
  position: unset;
  top: 0;
  z-index: 10;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.login-item {
  display: flex;
  margin-left: 2rem;
}

.logged-in-item {
  display: flex;
  align-items: center;
  margin-left: 4rem;
}

.navbar-active {
  color: #376FCC;
}

.logged-in-item > p {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;  
}

.navbar-uncle {
  width: 50px;
  clip-path: circle();
}

.navbar-arrow-down {
  width: 20px;
  margin-right: 5px;
}

.navbar-item {
  margin: 20px;
  font-size: 15px;
  cursor: pointer;
}

.navbar-login {
  margin-right: 5px;
}

.navbar-login {
  background: #376FCC;
  color: white;
  width: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  border-radius: 10px;
}

.navbar-register {
  background: white;
  color: #376FCC;
  width: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  border-radius: 10px;
  border-style: solid;
  margin-left: 0.5rem;
}

.three-lines {
  display: none;
  width: 40px;
  height: 40px;
}

.navbar-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo-title {
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
}

.logo-title > img {
  width: 80px;
}

.logo-title > p {
  margin-left: 2rem;
}

.navbar-item {
  white-space: nowrap;
}

.profile-dropdown {
  flex-direction: column;
  margin-left: 2rem;
}

.profile-dropdown > .navbar-item {
  margin: 1rem;
}

@media screen and (max-width: 980px) {

  .logo-title > p {
    display: none;
  }

  .navbar {
    margin-left: 3rem;
    margin-right: 3rem;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .navbar-content {
    display: none
  }

  .navbar-content.opened {
    display: block;
    break-before: always;
  }

  .three-lines {
    display: block;
    break-after: always;
    width: 25px;
    height: 25px;
  }

  .navbar-login {
    margin-left: -0.5rem !important;
  }

  .navbar-register {
    margin-left: -0.5rem !important;
  }

  .login-item {
    display: block;
  }

  .logged-in-item {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 600px) {

  .logo-title > p {
    display: none;
  }

  .navbar {
    margin-left: 3rem;
    margin-right: 3rem;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .navbar-content {
    display: none
  }

  .navbar-content.opened {
    display: block;
    break-before: always;
  }

  .three-lines {
    display: block;
    break-after: always;
    width: 25px;
    height: 25px;
  }

  .navbar-login {
    margin-left: -0.5rem !important;
  }

  .navbar-register {
    margin-left: -0.5rem !important;
  }

  .login-item {
    display: block;
  }

  .logged-in-item {
    margin-left: 2rem;
  }
}