.not-found-container {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-container > h1 {
    color: darkslategrey;
}