.port-container {
  
}
.main-port-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}
.demo-container {
  padding: 10px 19px 10px 19px;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  width: 80%;
  height: 72px;
}
.inner-container {
  display: flex;
  justify-content: space-between;
}
.inner-container-left {
  display: flex;
  flex-direction: row;
}
.left-container-header {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-left: 20px;
}
.left-title {
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 21px;
  font-size: Poppins;
}
.left-desc {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 21px;
  font-size: Poppins;
}
.icon-size {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.third-container-port{
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  width: 80%;
  height: 72px;
  padding: 20px 20px 20px 20px;
}
.image-container{
  margin-top: 20px;
}

@media screen and (max-width: 900px) {
  .main-port-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .demo-container {
    width: 100%;
  }
  .third-container-port {
    width: 100%;
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .left-container-header {
    font-size: 14px;
  }
  .left-title {
    font-size: 12px;
  }
  .left-desc {
    font-size: 12px;
  }
  .third-container-port {
    height: 100px;
  }
  .icon-size {
    width: 20px;
    height: 20px;
  }
}
