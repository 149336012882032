.alpha-deny_req_model_des {
  display: flex;
  flex-direction: column;
  padding: 4rem 3rem 2rem 3rem;

}

.alpha-deny_req_model_des h3 {
  font-family: lato;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #000000;
}

.alpha-deny_req_model_input_view {
  display: flex;
  padding-right: 3rem;
  padding-left: 3rem;
}