.contact-page-about-container {
    display: flex;
    padding: 5rem;
}

.about-page-about-container {
    display: flex;
    padding: 5rem;
}

.about-page-about-selection {

}


.about-page-about-selection-heading {
    font-family: Open Sans;
    font-size: 38px;
    font-weight: 700;
    width: 20rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
}

.about-page-about-selection-options {
    border: solid 1px #E1E4E8;
    border-radius: 10px;
    width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 25rem;
}

.about-page-about-container {
    display: flex;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 5rem;
    padding-top: 2rem;
}

.about-page-about-selection-options > h2 {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    padding: 2rem
}

.about-page-about-selected-option {

}

.about-page-about-selection-content {
    display: flex;
    margin-left: 5rem;
    margin-top: 1rem;
    width: 80%;
}

.about-page-about-selection-content > * {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
}

.about-page-about-selected-option {
    color: #376FCC
}

.about-page-about-selection-content-container {
    display: flex;
}

@media screen and (max-width: 600px) {

    .about-page-about-container {
        padding-bottom: 3rem;
    }

    .about-page-about-selection-heading {
        margin-left: 0px;
    }

    .about-page-about-selection-content-container {
        flex-direction: column;
    }

    .about-page-about-selection-options {
        width: unset;
    }

    .about-page-about-selection-content {
        width: unset;
        margin-top: 3rem;
        margin-left: 0px;
    }
}