.customer-contant {
    width: 100%;
    height: auto; /* Adjust height as needed */
  }
  
  .main-customer-conatiner {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .main-customer-support-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .contact-title {
    font-size: 48px;
    font-weight: 600;
    color: #000;
    font-family: Open Sans;
  }
  
  .main-customer-support-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .customer-desc {
    width: 70%;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    line-height: 30px;
    color: #666666;
  }
  
  .customer-email,
  .customer-phone,
  .customer-support {
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    line-height: 30px;
    color: #666666;
  }
  
  .customer-form {
    width: 100%;
    max-width: 500px; 
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .customer-form-title {
    color: #666666;
    font-size: 18px; 
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .customer-form form {
    display: flex;
    flex-direction: column;
  }
  
  .customer-form .form-group {
    width: 100%;
  }
  
  .customer-form input[type="text"],
  .customer-form textarea {
    width: 100%;
    padding: 14px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    color: #333;
    margin-top: 20px;
  }
  
  .customer-form textarea {
    height: 150px;
  }
  
  .customer-form button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .agreement-statement {
    margin-top: 20px;
    font-size: 14px;
    color: #666666;
    text-align: center;
  }  
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }


  @media screen and (max-width: 1024px) {
    .main-customer-conatiner {
      flex-direction: column;
    }
  
    .main-customer-support-left {
      width: 100%;
    }
  
    .main-customer-support-right {
      width: 100%;
    }
  }