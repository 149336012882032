.mainContainer{
   margin-top: 10px;
}
.card {
    width: 573px;
    height: min-content;
    padding: 12px;
    outline: solid 1px #e2e2e2;
    outline-offset: -1px;
    border-radius: 16px;
    background-color: #fff;
    margin-bottom: 16px;
  }
  .card-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .userDetail {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  .iAmUser  {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: Open Sans, sans-serif;
    line-height: 24px;
  }
  .loremIpsumDolorSitAmetConsecte {
    color: #7F7F7F;
    font-size: 14px;
    font-weight: 400;
    font-family: Open Sans, sans-serif;
    line-height: 22px;
  }
  /* .profile {
    width: 52px;
    height: 52px;
    overflow: visible;
  } */
  .icon-container {
    width: 10%;
    height: 10%;
  }
  .continue-as-guest {
    margin-top: 2rem;
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;
}

.continue-as-guest > span {
    font-family: Inter;
    font-size: 14px;
    font-weight: bold;
    color: grey;
    margin-right: 2px;
    margin-bottom: 2rem;
}
.selected {
  border: #376FCC;
  border-width: 2px;
  border-style: solid;
}

@media screen and (max-width: 1200px) {
  .card {
    width: 400px;
  }
}