.profile-container{
   padding:  20px;
}
.second-container {
    padding: 10px 19px 10px 19px;
    border: 1px solid #e2e2e2;
    border-radius: 12px;
    width: 30%;
    height: 72px;
    margin-top: 10px;
  }
  .third-container {
    /* padding: 10px 19px 10px 19px; */
    border: 1px solid #e2e2e2;
    border-radius: 12px;
    width: 30%;
    height: 72px;
    margin-top: 10px;
    padding: 20px;
  }
  .inner-container {
    display: flex;
    justify-content: space-between;
  }
  .inner-container-left {
    display: flex;
    flex-direction: row;
  }
  .left-container-header {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin-left: 20px;
  }
  .left-title {
    font-size: 14px;
    font-weight: 400;
    color: #cccccc;
    line-height: 21px;
    font-size: Poppins;
  }
  .left-desc {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 21px;
    font-size: Poppins;
  }
  .icon-size {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  .photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .profile-image {
    width: 155px;
    height: 155px;
    border-radius: 50%;
  }
  .profile-image-container {
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-self: flex-start; */
    /* background-color: #000000; */
    margin-top: 20px;
    margin-right: 290px;
  }

  .edit-input {
    width: 100%;
    height: 72px;
    border: 1px solid #e2e2e2;
    border-radius: 12px;
    padding: 10px 19px 10px 19px;
    margin-top: 10px;
  }

  @media screen and (max-width: 1100px) {
    .profile-container {
      padding: 20px;
    }
    .second-container {
      width: 100%;
    }
    .third-container {
      width: 100%;
    }
    .profile-image {
      width: 100px;
      height: 100px;
    }
    .profile-image-container {
      margin-right: 0px;
    }
  }