.icon {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
}
.camera-img {
    width: 20px;
    margin-top: -2rem;
    margin-left: 8rem;
}