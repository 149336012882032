.alpha-card_modal_inputs_view {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}

.alpha-card_modal_inputs_view_two {
  display: none;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}

.alpha-card_modal_date_input_top_view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 85%;
}

@media screen and (max-width:600px) {
  .alpha-card_modal_date_input_top_view {
    align-items: flex-start;
    flex-direction: column;
    margin-left: 0rem;
  }

  .alpha-card_modal_inputs_view {
    display: none;
  }

  .alpha-card_modal_inputs_view_two {
    display: flex;
    margin-bottom: 2rem;
  }

}