.choice-input {
    display: flex;
    flex-direction: column;
    position: relative;
}

.choice-input > label {
    font-family: Open Sans;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 10px;
}

.choice-input-clickable > img {
    width: 15px;
    margin-right: 10px;
}

.choice-input-clickable {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    width: 40rem;
    height: 6rem;
    background-color: #F1F1F1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    border: none;
    border-radius: 5px;
}

.choice-input-options {
    position: absolute;
    height: 150px;
    overflow-y: auto;
    top: 7rem;
    border: 1px solid black;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.choice-input-options > p {
    margin-left: 20px;
    margin-bottom: 4px;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;    
    align-self: flex-start;
}

.separator-choice {
    background: #E1E4E8;
    height: 1px;
    width: 90%;
    border: solid 1px #E1E4E8;
}

@media screen and (max-width: 600px) {
    .choice-input-clickable {
        width: 22rem !important;
    }   
}
