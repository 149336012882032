.aboutus-des {
    font-size: 18px;
    line-height: 26px;
    color: #7f7f7f;
    margin-bottom: 30px;
    margin-top: 10px;
    font-weight: 400;
}
.about-content{
    padding: 20px;
 
}