.alpha-delete_product_cross_view {
  display: flex;
  padding-top: 1.6rem;
  padding-right: 2.6rem;
  justify-content: flex-end;
}

.alpha-delete_product_cross_view img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.alpha-delete_product_title_view {
  display: flex;
  align-items: center;
  margin-top: -1rem;
  flex-direction: column;
}

.alpha-delete_product_title_view h1 {
  font-family: lato;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.4rem;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 4rem;
}

.alpha-delete_product_title_view h2 {
  font-family: lato;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
  color: #000000;
  padding-bottom: 4rem;
}

.alpha-delete_product_buttons_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 3.3rem;
  padding-right: 3.3rem;
  padding-bottom: 3.7rem;
}

.alpha-delete_product_buttons_view div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.4rem;
  height: 3.1rem;
  border-radius: 5px;
  cursor: pointer;
}

.alpha-delete_product_buttons_view h3 {
  font-family: lato;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.7rem;
  color: #FFFFFF;
}