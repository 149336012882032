@font-face {
  font-family: "lato";
  src: local("LatoLight"),
    url("./assets/fonts/Lato-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "lato";
  src: local("LatoRegular"),
    url("./assets/fonts/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "lato";
  src: local("LatoSemibold"),
    url("./assets/fonts/Lato-Semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "lato";
  src: local("LatoBold"),
    url("./assets/fonts/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
}

.opacity-disabled {
  opacity: 0.3;
  cursor: auto;
}

:root {

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-white: #ffffff;
  --color-theme: #006039;
}

html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
}