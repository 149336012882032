.text-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.text-container > h1 {
  width: 40rem;
}
.image-container {
    width: 40rem;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.upload-image-warpper {
  display: flex;
  /* justify-content: center;  */
  margin-bottom: 1rem;
}
.image-item {
  margin-right: 1rem;
}
.image {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 1rem;
}
.custom-text-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.custom-text-input > label {
    font-family: Open Sans;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .custom-text-input > input, .text-input > textarea, .password-input-container > input {
    border: none;
    border-radius: 5px;
    height: 5rem;
    width: 40rem;
    padding: 20px;
    background-color: #F1F1F1;
  }

  .facilities {
    padding: 10px;
    width: 40rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .facility {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: #376FCC;
    padding: 10px;
    color: white;
    border-radius: 15px;
  }
  .remove-image {
    background-color: #376FCC;
    color: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    border-color: #376FCC;
    
  }