.text-input {
  display: flex;
  flex-direction: column;
}

.given-container {
  width: 40rem;
}

.text-input > label {
  font-family: Open Sans;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.text-input > input, .text-input > textarea, .password-input-container > input {
  border: none;
  border-radius: 5px;
  height: 5rem;
  width: 40rem;
  padding: 20px;
  background-color: #F1F1F1;
}

.text-input > textarea {
  min-height: 20rem;
}

.input-error {
  font-size: 10px;
  color: red;
}

@media screen and (max-width: 600px) {
  .text-input > input, .text-input > textarea, .password-input-container > input {
    width: unset;
  }

  .given-container {
    width: unset;
  }
}