.alpha_finance_steps_admin_add_image_two {
    width: 7.6rem;
    height: 7.2rem;
    object-fit: cover;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.alpha_finance_steps_admin_image_two {
    width: 7rem;
    height: 7rem;
    object-fit: cover;
    border-radius: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
}



@media screen and (max-width:600px) {


    .alpha_finance_steps_admin_add_image_two {
        width: 5.6rem;
        height: 5.2rem;
    }

    .alpha_finance_steps_admin_image_two {
        width: 5rem;
        height: 5rem;
        border-radius: 1rem;
    }

}