.alpha_home-page-spare_parts_top_view {
  display: flex;
  flex-direction: column;
  width: 23.6rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  background: #ffffff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  cursor: pointer;
}

.alpha-home-page-spare_part_image_style {
  height: 19.2rem !important;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  object-fit: cover;
}

.alpha-home-page-spare_part_detail_view {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  flex: 1
}

.alpha-home-page-spare_part_detail_view h2 {
  font-family: lato;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #000000;
}

.alpha-home-page-spare_part_detail_view h3 {
  font-family: lato;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #858585;
  padding-top: 0.6rem;
  padding-bottom: 0.8rem;
}

.alpha-home-page-spare_part_price_distance_view {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
}

.alpha-home-page-spare_part_price_view {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.alpha-home-page-spare_part_price_view h1 {
  font-family: lato;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #F18805;
}

.alpha-home-page-spare_part_price_view h4 {
  font-family: lato;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #F18805;
}

.alpha-home-page-spare_part_price_view img {
  width: 2rem !important;
  height: 2rem !important;
  margin-right: 0.8rem;
  object-fit: cover;
}

.alpha-home-page-spare_part_price_view h5 {
  font-family: lato;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #101010;
}

@media screen and (max-width: 400px) {
  .alpha-home-page-spare_part_detail_view h2 {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .alpha-home-page-spare_part_detail_view h3 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .alpha-home-page-spare_part_price_view h1 {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .alpha-home-page-spare_part_price_view h4 {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .alpha-home-page-spare_part_price_view img {
    width: 1.6rem !important;
    height: 1.6rem !important;
  }

  .alpha-home-page-spare_part_price_view h5 {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}