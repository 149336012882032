.alpha-review_model-summary_top_view {
  display: flex;
  flex-direction: column;
  margin: 3rem 3rem 2rem 3rem;
}

.alpha-review_model-summary_top_view h2 {
  font-family: lato;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 2.5rem;
  color: #F18805;
  padding-bottom: 2rem;
}

.alpha-review_model_product_info_view {
  display: flex;
  flex-direction: row;
}

.alpha-review_model_product_info_view img {
  width: 13rem;
  height: 9.4rem;
  margin-right: 1.2rem;
  border-radius: 5px;
}

.alpha-review_model_product_info_view h5 {
  font-family: lato;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: #000000;
}

.alpha-review_model_product_info_view h6 {
  font-family: lato;
  font-weight: 350;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #272727;
}

.alpha-review_model_product_info_view div {
  display: flex;
  flex-direction: column;
}

.alpha-review_model_payment_title_view {
  display: flex;
  padding-left: 3rem;
  padding-right: 3rem;
  flex-direction: column;
}

.alpha-review_model_payment_title_view h2 {
  font-family: lato;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.9rem;
  color: #F18805;
  padding-bottom: 0.8rem;
}

.alpha-review_model_payment_title_view h3 {
  font-family: lato;
  font-weight: 350;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #272727;
}

@media screen and (max-width:600px) {
  .alpha-review_model-summary_top_view h2 {
    font-size: 2rem;
    line-height: 2.3rem;
  }

  .alpha-review_model_product_info_view img {
    width: 12rem;
    height: 8.4rem;
  }

  .alpha-review_model_product_info_view h5 {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  .alpha-review_model_product_info_view h6 {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .alpha-review_model_payment_title_view h2 {
    font-size: 1.6rem;
    line-height: 1.7rem;
  }

  .alpha-review_model_payment_title_view h3 {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

@media screen and (max-width:470px) {
  .alpha-review_model_product_info_view {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .alpha-review_model-summary_top_view h2 {
    font-size: 1.8rem;
    line-height: 2.1rem;
  }

  .alpha-review_model_product_info_view img {
    width: 100%;
    height: 9.4rem;
    margin-bottom: 1rem;
  }

  .alpha-review_model_product_info_view h5 {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }

  .alpha-review_model_payment_title_view h2 {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
}

@media screen and (max-width:370px) {

  .alpha-review_model-summary_top_view h2 {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }


}