.button-container {
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.button-container > * {
  font-size: 16px;
  font-weight: 600;
}

.button-primary {
  background: #376FCC;
}

.button-primary > * {
  color: white;
}