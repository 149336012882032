.alpha_home-page-grid_inner_view {
  margin-right: 3rem;
  width: 40rem;
  display: flex;
  margin-bottom: 3rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  flex-direction: column;
  padding: 1.6rem;
  cursor: pointer;
}

.alpha_home-page-grid_inner_view_image {
  height: 21.9rem !important;
  border-radius: 0.5rem;
}

.alpha_home-page-grid_inner_view h1 {
  font-family: lato;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: #000000;
  padding-top: 1.2rem;
}

.alpha_home-page-grid_inner_view h2 {
  font-family: lato;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.1rem;
  padding-top: 1rem;
  color: #858585;
}

.alpha-home_page_auction_distance_view {
  margin-top: 1.1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
}

.alpha-home_page_auction_distance_view h3 {

  font-family: lato;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.2rem;
  color: #707070;
}

.alpha-home_page_auction_km_view {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}

.alpha-home_page_auction_km_view h4 {
  font-family: lato;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #101010;
}

.alpha-home_page_auction_km_view img {
  width: 2.4rem !important;
  height: 2.5rem !important;
  margin-right: 0.3rem;
  object-fit: contain;
}

.alpha-home_page_auction_online_view {
  margin-top: 0.9rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
}

.alpha-home_page_auction_online_view h3 {
  font-family: lato;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #F18805;
  cursor: pointer;
}

.alpha-home_page_auction_distance_view h5 {
  font-family: lato;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.2rem;
  color: #F18805;
}

.alpha-home_page_auction_online_view h4 {
  font-family: lato;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #858585;
}

@media screen and (max-width: 485px) {
  .alpha_home-page-grid_inner_view h1 {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .alpha_home-page-grid_inner_view h2 {
    font-size: 1.6rem;
    line-height: 1.7rem;
  }

  .alpha-home_page_auction_distance_view h3 {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  .alpha-home_page_auction_km_view h4 {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  .alpha-home_page_auction_km_view img {
    width: 2.2rem !important;
    height: 2.3rem !important;
  }

  .alpha-home_page_auction_online_view h3 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }


  .alpha-home_page_auction_online_view h4 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .alpha-home_page_auction_distance_view h5 {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
}

@media screen and (max-width: 405px) {
  .alpha_home-page-grid_inner_view h1 {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .alpha_home-page-grid_inner_view h2 {
    font-size: 1.2rem;
    line-height: 1.3rem;
  }

  .alpha-home_page_auction_distance_view h3 {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  .alpha-home_page_auction_km_view h4 {
    font-size: 1rem;
    line-height: 1rem;
  }

  .alpha-home_page_auction_km_view img {
    width: 2.1rem !important;
    height: 2.2rem !important;
  }

  .alpha-home_page_auction_online_view h3 {
    font-size: 1rem;
    line-height: 1.4rem;
  }


  .alpha-home_page_auction_online_view h4 {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .alpha-home_page_auction_distance_view h5 {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}