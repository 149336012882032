.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.home-background {
    background-image: url('../../../assets/icons/home-background.png');
    width: 90%;
    height: 420px;
    background-size: cover;
    background-repeat: no-repeat;
}

.home-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3rem;
}

.home-title {
    color: white;
    font-family: Open Sans;
    font-size: 45px;
}

.home-subtitle {
    color: white;
    font-size: 18px;
    font-family: Open Sans;
    font-weight: 400;
    word-wrap: break-word;
    width: 74rem;
}

.home-search-container {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-margin {
    margin-top: -50px;
}

.home-search-card {
    width: 85%;
    height: 100%;
    border-style: solid;
    border-width: 0;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 1px 5px 0 rgba(0,0,0,.18);
    -webkit-box-shadow: 0px 1px 5px 0 rgba(0,0,0,.18);
    -moz-box-shadow: 0px 1px 5px 0 rgba(0,0,0,.18);
    display: flex;
    justify-content: space-between;
    padding: 2rem;
}

.banner-card-child {
    height: 190px;
}

.home-search-field {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-search-field-text {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1rem;
    white-space: nowrap;
}

.home-search-field-input > input {
    background-color: #F1F1F1;
    color: black;
    width: 100%;
    height: 55px;
    top: 160px;
    left: 50px;
    border-radius: 12px;
    border-style: hidden;
    padding-left: 35px;
}

.home-search-field-input-dates {
    background-color: #F1F1F1;
    height: 55px;
    border-radius: 12px;
    border-style: hidden;
    padding-left: 35px;
    background: url("../../../assets/icons/calendar.png"), #F1F1F1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 5px;
    padding-left: 35px;
}

.home-search-field-input-times {
    background-color: #F1F1F1;
    height: 55px;
    border-radius: 12px;
    border-style: hidden;
    padding-left: 35px;
    background: url("../../../assets/icons/clock.png"), #F1F1F1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 5px;
    padding-left: 35px;
}

.banner-container {
    background-image: url('../../../assets/icons/map-image.png');
    width: 100%;
    height: 520px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -30px;
}

.banner {
    height: 100%; 
    display: flex; 
    align-items: center; 
    padding-left: 8rem; 
    padding-right: 8rem;
}

.banner-background {
    background-image: url('../../../assets/icons/map-image.png');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.banner-text {
    height: 100%;
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    margin-top: -150px;
}

.banner-title {
    color: white;
    font-family: Open Sans;
    font-size: 45px;
}

.banner-subtitle {
    color: white;
    font-size: 18px;
    font-family: Open Sans;
    font-weight: 400;
    word-wrap: break-word;
    width: 74rem;
}

.banner-card-container {
    width: 100%;
    height: 200px;
    display: flex;
    color: white;
    justify-content: center;
}

.banner-card {
    background: white;
    width: 60%;
    color: black;
    display: flex;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0 rgba(0,0,0,.18);
    -webkit-box-shadow: 0px 1px 5px 0 rgba(0,0,0,.18);
    -moz-box-shadow: 0px 1px 5px 0 rgba(0,0,0,.18);
}

.banner-card-book-now {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
    border: solid 1px #376FCC;
    border-radius: 10px;
    height: 5rem;
    width: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-search-card-button {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    align-self: flex-end;
    margin-bottom: 10px;
    background-color: #376FCC;
}

.home-search-card-button > img {
    filter: brightness(0) invert(1);
    width: 50px;
    height: 50px;
}

.banner-card-book-now-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    color: #376FCC;
}

.banner-card-coordinate-container {
    margin-top: .5rem;
    display: flex;
    justify-content: space-between;
    width: 25rem;
}

.banner-card-coordinate-container > p {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    white-space: nowrap;
}

.banner-card-content {
    margin-left: 20px;
}

.banner-card-content > h2 {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
}

.banner-card-content > h3 {
    margin-top: 1.5rem;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
}

.banner-card-content > p {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 1.5rem;
}

.banner-card-coordinate-container-details {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.banner-card-price {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 4rem;
}

.banner-card-price > h2 {
    display: flex;
    justify-content: center;
}

.card-content-children {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.info-container {
    display: flex;
    flex-direction: column;
    background: #F9F9F9;
}

.info-container > h2 {
    font-family: Open Sans;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.info-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10rem;
    padding-right: 10rem;
    padding-left: 10rem;
    padding-top: 8rem;
}

.info-icon-top {
    display: flex;
    justify-content: space-evenly;
    width: 66%;
}

.info-icon-top > img {
    width: 120px;
}

.info-icon-bottom {
    display: flex;
    flex-direction: column;
    width: 33%;
}

.info-icon-bottom > img {
    width: 120px;
}

.info-icon {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.overview-container {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 5rem;
    padding-right: 5rem;
    height: 630px;
}

.overview-text-points {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}
.port-selection  {
    
}
.registar-text-points   {
    margin: 2rem;
}
.registar-text-title {
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    color: #000000;

}
.registar-text-desc {
    font-family: Open Sans;
    font-size: 16px;
    margin-top: 1rem;
    font-weight: 400;
    text-align: left;
    color: #000000;
}
.btn-container {
    width: 30%;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    background: #376FCC;

}
.btn-container  > * {
    font-size: 16px;
    font-weight: 600;
  }
  .btn-container > * {
    color: white;
  }
.overview {
    display: flex;
    margin-top: 5rem;
}

.overview-point-number {
    background: blue;
    color: white;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 20px;
}

.overview-point-title {
    margin-top: 1rem;
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
}

.overview-point-desc {
    margin-top: 1rem;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.overview-point {
    margin-top: 3rem;
}

.overview-text-points {
    margin: 3rem;
}

.overview-image {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

}

.overview-image > img {
    /* width: 20rem; */
    display: flex;
    justify-content: space-between;
    height: 100%;

}

.about-container {
    display: flex;
    background: #F9F9F9;
    margin-top: 5rem;
    justify-content: center;
    padding: 5rem;
}

.about-icon {
    display: flex;
    align-items: center;
}

.about-icon > img {
    width: 120px;
    height: 120px;
    margin-right: 3rem;
}

.about-icon > h2 {
    width: 20rem;
    font-family: Open Sans;
    font-size: 30px;
    font-weight: 700;
}

.about-text {
    width: 50%;
    margin: 5rem;
}

.about-text > p {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;    
}

.read-more-about {
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: #376FCC;
    cursor: pointer;
}

.read-more-about > p {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;    
}

.read-more-about > img {
    margin-left: 5px;
    height: 20px;
    width: 20px
}

.contact-us-container {

}

.contact-us-text {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}

.contact-us-text > h2 {
    font-family: Inter;
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 2rem;
}

.contact-card-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3rem;
}

.contact-card {
    display: flex;
    border-style: solid;
    align-items: center;
    border-radius: 20px;
    border-color: #E1E4E8;
    border-width: 1px;
    padding: 1rem;
    width: 30%;
    height: 140px;
}

.contact-card-icon {

}

.contact-card-icon > img {

}

.contact-card-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 2rem;
}

.contact-card-text > h2 {
    font-size: 20px;
}

.banner-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-inner-card {
    display: flex;
}

.contact-card-text > p {
    margin-top: 1rem;
    font-size: 13px;
}

.app-download-container {

}

.app-download-container > h2 {
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    font-family: Open Sans;
    font-size: 38px;
    font-weight: 700;
    text-align: center;
}

.store-button-icon {

}

.store-button-icon > img {

}

.store-button {
    width: 15%;
    display: flex;
    padding: 10px;
    border: solid 1px black;
    border-radius: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
}

.store-button-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}

.app-icon-container {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}

.store-button-text > h2 {

}

.store-button-text > h3 {

}

.footer {
    margin-top: 5rem;
}

.separator {
    border: 1px solid #C7CACD;
}

.footer-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 5rem;
    height: 280px;
}

.footer-logo {

}

.footer-logo > img {
    width: 80%;
    height: auto;
}

.footer-links {

}

.footer-links > h2 {
    margin-top: 3rem;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 3rem;
}

.footer-links > p {
    margin-top: 1rem;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
}

.footer-social-icons {
    display: flex;
}

.footer-social-icons > img {
    margin-right: 2rem
}

.footer-copyright {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.footer-copyright > p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;    
}

.footer-social-icons > img {
    width: 20px;
}

.store-button-icon > img {
    width: 50px;
}

.contact-card-icon > img {
    width: 65px;
}

.home-result-font {
    font-size: 11px !important;
}

.footer-content-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

@media only screen and (max-width: 980px){

    .home-subtitle {
        width: 100%
    }

    .store-button-icon > img {
        width: 70px;
        height: auto;
    }

    .contact-card-icon > img {
        width: 70px;
        height: auto;
    }

    .home-search-container {
        height: unset;
        margin-top: -10px;
    }

    .home-margin {
        margin-top: -10px;
    }

    .home-search-card {
        flex-direction: column;
    }

    .home-search-field-input > input {
        width: 100%;
        margin-bottom: 20px;
    }

    .banner-background {
        height: unset;
    }

    .banner-container {
        height: unset;
    }

    .banner-card-price {
        margin-top: 2rem;
        padding-left: 20px;
        align-items: flex-start;
    }

    .banner-card-container {
        justify-content: center !important;
        height: unset;
    }

    .home-search-card-button {
        align-self: flex-start;
    }

    .banner {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        width: unset;
    }

    .banner-card {
        flex-direction: column;
        width: 350px !important;
        margin-top: 3rem;
    }

    .banner-card-content {
        margin-top: 20px;
    }

    .banner-card-content > p {
        margin-top: 0;
    }

    .banner-card-coordinate-container {
        margin-top: .5rem;
    }

    .banner-card-price {

    }

    .card-content-children {
        flex-direction: column;
    }

    .home-search-field-input {
        width: 100%;
    }

    .home-search-field-input-dates {
        width: 100%;
    }

    .home-search-field-input-times {
        width: 100%;
    }

    .home-search-card-button {
        margin-top: 1rem;
    }

    .banner-text {
        justify-content: center;
        width: 100%;
        margin-top: 5rem;
    }

    .banner-title {
        font-size: 35px;
    }

    .banner-inner-card {
        flex-direction: column;
    }

    .banner-subtitle {
        width: 100%
    }

    .info-icon-container {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
    }

    .info-icon {
        margin-left: 2rem;
        margin-right: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 2rem;
    }

    .info-icon-top {
        width: 100%;
    }

    .info-icon-bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
    }

    .info-icon-top > img {
        width: 100px;
    }

    .info-icon-bottom > img {
        width: 100px;
    }

    .overview-container {
        margin-left: unset;
        margin-right: unset;
        padding-left: unset;
        padding-right: unset;
        height: unset;
    }

    .overview {
        flex-direction: column;
        margin: 0px;
    }

    .overview-image {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .overview-text-points {
        margin-left: 3rem;
        margin-right: 3rem;
        margin-top: 0px;
    }

    .overview-image > img {
        max-width: 100%;
        max-height: 500px;
    }

    .about-container {
        flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .about-icon {
        flex-direction: column;
        text-align: center;
    }

    .about-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: unset;
        margin: 0px;
        margin-top: 2rem;
    }

    .contact-card-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-card {
        width: 90%;
        margin-bottom: 2rem;
    }

    .contact-us-text {
        margin-top: 2rem;
    }

    .app-download-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    .info-icon-container {
        padding-top: 3rem;
    }

    .app-download-container > h2 {
        margin-top: 0px;
        font-size: 25px;
    }

    .app-icon-container {
        flex-direction: row !important;
    }

    .store-button {
        width: unset;
        margin-bottom: 2rem;
    }

    .footer-content-container {
        flex-direction: column;
    }

    .footer-content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 0px;
        height: unset;
    }

    .footer-logo {
        align-self: center;
    }

    .footer-links {
        margin: 2rem;
    }

    .footer-links > h2 {
        margin-bottom: 0px;
    }

    .footer-social-icons {
        margin-top: 10px;
    }

    .ant-picker-panels {
        flex-direction: column;
    }
}


@media only screen and (max-width: 600px){

    .home-subtitle {
        width: 100%
    }

    .store-button-icon > img {
        width: 70px;
        height: auto;
    }

    .contact-card-icon > img {
        width: 70px;
        height: auto;
    }

    .home-search-container {
        height: unset;
        margin-top: -10px;
    }

    .home-margin {
        margin-top: -10px;
    }

    .home-search-card {
        flex-direction: column;
    }

    .home-search-field-input > input {
        width: 200px;
        margin-bottom: 20px;
    }

    .banner-background {
        height: unset;
    }

    .banner-container {
        height: unset;
    }

    .banner-card-price {
        margin-top: 2rem;
        padding-left: 20px;
        align-items: flex-start;
    }

    .banner-card-container {
        justify-content: center !important;
        height: unset;
    }

    .home-search-card-button {
        align-self: flex-start;
    }

    .banner {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        width: unset;
    }

    .banner-card {
        flex-direction: column;
        width: 350px !important;
        margin-top: 3rem;
    }

    .banner-card-content {
        margin-top: 20px;
    }

    .banner-card-content > p {
        margin-top: 0;
    }

    .banner-card-coordinate-container {
        margin-top: .5rem;
    }

    .banner-card-price {

    }

    .card-content-children {
        flex-direction: column;
    }

    .home-search-field-input {
        width: 20rem;
    }

    .home-search-card-button {
        margin-top: 1rem;
    }

    .banner-text {
        justify-content: center;
        width: 100%;
        margin-top: 5rem;
    }

    .banner-title {
        font-size: 35px;
    }

    .banner-inner-card {
        flex-direction: column;
    }

    .banner-subtitle {
        width: 100%
    }

    .info-icon-container {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
    }

    .info-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .info-icon-top {
        width: 100%;
    }

    .info-icon-bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
    }

    .info-icon-top > img {
        width: 100px;
    }

    .info-icon-bottom > img {
        width: 100px;
    }

    .overview-container {
        margin-left: unset;
        margin-right: unset;
        padding-left: unset;
        padding-right: unset;
        height: unset;
    }

    .overview {
        flex-direction: column;
        margin: 0px;
    }

    .overview-image {
        margin-top: 2rem;
    }

    .overview-text-points {
        margin-left: 3rem;
        margin-right: 3rem;
        margin-top: 0px;
    }

    .overview-image > img {
        max-width: 100%;
        max-height: 500px;
    }

    .about-container {
        flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .about-icon {
        flex-direction: column;
        text-align: center;
    }

    .about-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: unset;
        margin: 0px;
        margin-top: 2rem;
    }

    .contact-card-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-card {
        width: 90%;
        margin-bottom: 2rem;
    }

    .contact-us-text {
        margin-top: 2rem;
    }

    .app-download-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    .info-icon-container {
        padding-top: 3rem;
    }

    .app-download-container > h2 {
        margin-top: 0px;
        font-size: 25px;
    }

    .app-icon-container {
        flex-direction: column !important;
    }

    .store-button {
        width: unset;
        min-width: 80%;
        margin-bottom: 2rem;
    }

    .footer-content-container {
        flex-direction: column;
    }

    .footer-content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 0px;
        height: unset;
    }

    .footer-logo {
        align-self: center;
    }

    .footer-links {
        margin: 2rem;
    }

    .footer-links > h2 {
        margin-bottom: 0px;
    }

    .footer-social-icons {
        margin-top: 10px;
    }

    .ant-picker-panels {
        flex-direction: column;
    }
}

@media  screen and (max-width: 1180px){
    .registar-text-desc {
        display: none;
    }
    .registar-text-title {
        font-size: 20px;
    }
}