.alpha_finance_steps_admin_image_top_view {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}

.alpha_finance_steps_admin_image_top_view h2 {
    font-family: lato;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #0E191E;
    padding-bottom: 1rem;
}

.alpha_finance_steps_admin_image_top_view h5 {
    font-family: lato;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #272727;
    justify-content: center;
    display: flex;
    padding-bottom: 3rem;
    padding-top: 2rem;
}

.alpha_finance_steps_admin_image_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.alpha_finance_steps_admin_add_image {
    width: 9.6rem;
    height: 9.2rem;
    border-radius: 2rem;
    object-fit: cover;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.alpha_finance_steps_admin_image {
    width: 9rem;
    height: 9rem;
    object-fit: cover;
    border-radius: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem;

}

.alpha-financing-finance_step_two_admin_buttons_top_view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.6rem;
}

.alpha-financing-finance_step_two_admin_back_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16.1rem;
    height: 4.8rem;
    background: #D2D2D2;
    border-radius: 1rem;
    cursor: pointer;
    margin-right: 2rem;
}

.alpha-financing-finance_step_two_admin_back_button h2 {
    font-family: lato;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    color: #717171;
}


.alpha-financing-finance_step_two_admin_next_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16.1rem;
    height: 4.8rem;
    background: #F18805;
    border-radius: 1rem;
    cursor: pointer;
}

.alpha-financing-finance_step_two_admin_next_button h2 {
    font-family: lato;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media screen and (max-width:600px) {
    .alpha_finance_steps_admin_image_top_view h2 {

        font-size: 1.6rem;
        line-height: 2.2rem;
    }

    .alpha_finance_steps_admin_add_image {
        width: 7.6rem;
        height: 7.2rem;
    }

    .alpha_finance_steps_admin_image {
        width: 7rem;
        height: 7rem;
        object-fit: cover;
        border-radius: 2rem;
        margin-right: 2rem;
    }

    .alpha-financing-finance_step_two_admin_back_button {
        width: 12.1rem;
        height: 3.8rem;

    }

    .alpha-financing-finance_step_two_admin_back_button h2 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }


    .alpha-financing-finance_step_two_admin_next_button {
        width: 12.1rem;
        height: 3.8rem;
    }

    .alpha-financing-finance_step_two_admin_next_button h2 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    .alpha-financing-finance_step_two_admin_buttons_top_view {
        display: flex;
        justify-content: flex-start;
    }
}

@media screen and (max-width:360px) {
    .alpha_finance_steps_admin_image_top_view h2 {
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .alpha_finance_steps_admin_add_image {
        width: 5.6rem;
        height: 5.2rem;
    }

    .alpha_finance_steps_admin_image {
        width: 5rem;
        height: 5rem;
        border-radius: 1rem;
    }

    .alpha-financing-finance_step_two_admin_back_button {
        width: 10.1rem;

    }

    .alpha-financing-finance_step_two_admin_back_button h2 {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }


    .alpha-financing-finance_step_two_admin_next_button {
        width: 10.1rem;
    }

    .alpha-financing-finance_step_two_admin_next_button h2 {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
}