.book-now-page-book-now-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.book-now-page-book-now-content {

}

.book-now-page-calculation-section {
    display: flex;
    flex-direction: column;
}

.book-now-page-calculation-featue {
    display: flex;
    flex-direction: column;
}

.book-now-page-calculation-featue > h2 {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 700;
}

.book-now-page-calculation-featue-price {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.book-now-page-calculation-featue-price > p {
    width: 847px;
    height: 19px;
    top: 626px;
    left: 323px;    
}

.book-now-page-calculation-featue-price > h2 {
    white-space: nowrap;
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    color: #999999;
}

.book-now-text-big {
    white-space: nowrap;
    font-family: Open Sans !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.book-page-book-now-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
}

.book-now-page-calculation-featue-price > p > span {
    font-family: Open Sans;
    font-size: 14px;
}

.book-now-page-calculation-featue-error-message {
    color: red;
    font-size: 11px;
    margin-bottom: 2px;
    font-weight: bold;
}

@media only screen and (max-width: 980px) {

    .book-now-page-calculation-featue-price > p {
        height: unset
    }

}


@media only screen and (max-width: 600px) {

    .booking-done-page-check-container {
        width: unset;
    }

    .book-now-page-calculation-featue-price > p {
        height: unset
    }

}
