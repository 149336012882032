.alpha-financing-model_top_view {
  display: flex;
  position: absolute;
  height: 100vh;
  overflow: scroll;
  padding-top: 2rem;
  width: 100vw;
  z-index: 999;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.alpha-financing-model_view {
  display: flex;
  width: 36.3rem;
  background: #FFFFFF;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 4rem;

}

.alpha-financing-model_view img {
  width: 11.2rem;
  height: 11.4rem;
  object-fit: fill;
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.alpha-financing-model_view h1 {
  font-family: lato;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  color: #2D292A;
  padding-bottom: 1.2rem;
}

.alpha-financing-model_view h2 {
  font-family: lato;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  color: #666666;
}

.alpha-financing-model_button_view {
  background: #F18805;
  border-radius: 10px;
  width: 98%;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  cursor: pointer;
}

.alpha-financing-model_button_view h3 {
  font-family: lato;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.alpha-financing-model_view h4 {
  font-family: lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #666666;
  padding-top: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .alpha-financing-model_view div {
    width: 30.3rem;
    height: 31.5rem;
  }

  .alpha-financing-model_view img {
    width: 8.2rem;
    height: 8.4rem;
  }

  .alpha-financing-model_view h1 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .alpha-financing-model_view h2 {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .alpha-financing-model_button_view {
    width: 98%;
    height: 4.8rem !important;

  }

  .alpha-financing-model_button_view h3 {
    font-size: 14px;
    line-height: 14px;
  }

  .alpha-financing-model_view h4 {
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 400px) {
  .alpha-financing-model_button_view h3 {
    font-size: 12px;
    line-height: 12px;
  }

  .alpha-financing-model_view h4 {
    font-size: 12px;
    line-height: 15px;
  }

  .alpha-financing-model_view {
    width: 28rem;
  }

  .alpha-financing-model_view div {
    width: 25.3rem;
    height: 25.5rem;
  }

  .alpha-financing-model_view img {
    width: 6.2rem;
    height: 6.4rem;
  }

  .alpha-financing-model_view h1 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .alpha-financing-model_view h2 {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}