.alpha-input_container_three {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
  position: relative;
}

.alpha-input_container_three p {
  font-family: lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #0E191E;
}

.alpha-input_container_three_input {
  margin-top: 0.6rem;
  width: 34rem;
  background-color: rgba(217, 217, 217, 0.1);
  border-radius: 1rem;
  border: 1px solid #D9D9D9;
  padding: 1.2rem 0rem 1.2rem 1.2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alpha-input_container_three_input input {
  width: 100% !important;
  display: flex;
  padding-right: 1rem;
  background-color: transparent;
  outline: none;
  border: none;
  font-family: lato;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #000000;
}

::-webkit-input-placeholder {
  color: #A8A8A8;
  font-family: lato;
  font-weight: 300;
}


@media screen and (max-width: 470px) {

  .alpha-input_container_three_input {
    width: 30rem;
  }

}

@media screen and (max-width: 370px) {

  .alpha-input_container_three_input {
    width: 25rem;
  }

}