.update-profile-container {
    display: flex;
    margin-top: 7rem;
}

.update-profile-menu {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    margin-right: 15vw;
    width: 30rem;
}

.mein-section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mein-section-container > .uncle-img {
    clip-path: circle();
    z-index: -100;
    width: 150px;
}

.mein-section-container > .camera-img {
    width: 20px;
    margin-top: -2rem;
    margin-left: 8rem;
}

@media screen and (max-width: 980px) {

    .update-profile-container > .mein-section-container > .text-input {
        width: 80% !important;
    }

    .update-profile-container > .mein-section-container > .text-input > input {
        width: 100% !important;
    }

    .update-profile-container > .mein-section-container > .choice-input {
        width: 80% !important;
    }

    .update-profile-container > .mein-section-container > .choice-input > .choice-input-clickable {
        width: 100% !important;
    }

    .update-profile-container {
        flex-direction: column;
    }

    .update-profile-menu {
        margin-right: unset;
        width: 100%;
    }    

    .mein-section-container {
        margin-top: 10px;
    }

    .mein-section-container > .text-input > input {
        width: 30rem !important;
    }

    .mein-section-container > .choice-input > .choice-input-clickable {
        width: 30rem !important;
    }

    .mein-section-container > .button-container {
        width: 30rem !important;
    }

    .bookings-container {
        flex-direction: column;
    }

    .bookings-menu {
        width: 100%
    }

}

@media screen and (max-width: 600px) {

    .update-profile-container {
        flex-direction: column;
    }

    .update-profile-menu {
        margin-right: unset;
        width: 100%;
    }    

    .mein-section-container {
        margin-top: 10px;
    }

    .mein-section-container > .text-input > input {
        width: 30rem !important;
    }

    .mein-section-container > .choice-input > .choice-input-clickable {
        width: 30rem !important;
    }

    .mein-section-container > .button-container {
        width: 30rem !important;
    }

    .bookings-container {
        flex-direction: column;
    }

    .bookings-menu {
        width: 100%
    }

}