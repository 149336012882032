.bookings-container {
    display: flex;
}

.bookings-menu {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.booking-card {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    border: 1px solid #E1E4E8;
    border-radius: 5px;
    padding-top: 2rem;
    width: 90%;
    padding-bottom: 2rem;
    margin-top: 3rem;
}


.booking-card-heading {
    display: flex;
    justify-content: space-between;
}

.booking-card-heading > h1 {
    margin-left: 1rem;
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
}

.booking-card-heading > img {
    width: 20px;
    margin-right: 5px;
}

.booking-card-options {

}

.booking-card-option {
    margin-top: 1.5rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.booking-card-option > h2 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;    
}

.booking-card-option > img {
    width: 20px;
    margin-right: 1rem;
}

@media screen and (max-width: 980px) {

    .bookings-container > .search-results-container > .banner-card-container > .banner-card {
        flex-direction: row !important;
        width: 90% !important
    }

    .bookings-container {
        flex-direction: column;
    }

    .bookings-menu {
        width: 100%
    }

    .booking-card {
        width: 95%;
    }

}

@media screen and (max-width: 600px) {

    .bookings-container {
        flex-direction: column;
    }

    .bookings-container > .search-results-container > .banner-card-container > .banner-card {
        flex-direction: column !important;
        width: 350px !important
    }

    .bookings-menu {
        width: 100%
    }

    .booking-card {
        width: 90%;
    }

}