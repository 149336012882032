.alpha_home-page_categories_view {
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: 24.6rem;
  background-color: #fff;
  border-radius: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;

}

.alpha_home-page_categories_view img {
  height: 20rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  object-fit: cover;
}

.alpha_home-page_categories_name_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem 0rem 0.8rem 2rem;
}

.alpha_home-page_categories_name_view h3 {
  font-family: lato;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #000000;
  padding-right: 0.8rem;
}

.alpha_home-page_categories_name_view h4 {
  font-family: lato;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3rem;
  color: #000000;
}

@media screen and (max-width: 400px) {
  .alpha_home-page_categories_name_view h3 {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .alpha_home-page_categories_name_view h4 {
    font-size: 1.4rem;
    line-height: 2.6rem;
  }
}

@media screen and (max-width: 332px) {
  .alpha_home-page_categories_name_view h3 {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  .alpha_home-page_categories_name_view h4 {
    font-size: 1rem;
    line-height: 2.2rem;
  }
}