.details-page-container {
    display: flex;
    margin-top: 50px;
}

.details-page-container-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5rem;
}

.details-page-container-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    width: 440px;
    margin-top: 2rem;
}

.details-page-container-right > h2 {
    margin-top: 2rem;
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 700;    
}

.details-page-container-right > p {
    margin-top: 2rem;
    font-family: Open Sans;
    font-size: 17px;
    font-weight: 400;
}

.details-page-details-images {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.details-page-details-images > img {
    border-radius: 10%;
    margin-top: 20px;
    width: 100px;
}

.details-page-details-section {
    margin-top: 3rem;
}

.details-page-details-section > h2 {
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;    
}

.details-page-details-section > h3 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
}

.details-page-perk-icon-container {
    margin-top: 2rem;
    display: flex;
}
.facility-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    background-color: #376FCC;
    padding: 5px;
    border-radius: 10px;
}
.facility-text {
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}

.details-page-perk-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #376FCC;
    height: 40px;
    width: 90px;
    margin-right: 20px;
    border-radius: 10px;
    padding: 2px;
}

.details-page-perk-icon > img {
    width: 20px;
    margin-right: 5px;
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
}

.details-page-perk-icon > p {
    color: white;
    margin-right: 5px;
    font-size: 12px;
}

.details-page-details-description {
    margin-top: 2rem;
    font-family: Open Sans;
    font-size: 17px;
    font-weight: 400;
}

.details-page-container-right {

}

.details-page-container-right > h1 {

}

.details-page-container-right > p {

}

.details-page-title-container {
    display: flex;
    width: 90%;
    justify-content: space-between;
}

.details-page-title-container > h1 {
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 700;
}

.details-page-rate-container {
    display: flex;
    align-items: flex-end;
    margin-top: -100px;
}

.details-page-rate-container > h2 {
    font-family: Open Sans;
    font-size: 30px;
    font-weight: 700;
}

.details-page-rate-container > p {
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 700;
}

.details-page-coordinate-container {
    margin-top: 2rem;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 300px;
    /* margin-left: 1rem; */
}

.details-page-coordinate-container > p {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
}

.details-page-availability {
    margin-top: 2rem;
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 2rem;
}

.details-page-time-nights-container {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 300px;
}

.details-page-button {
    align-self: flex-end;
    width: 200px;
    margin-top: 4rem;
}

.details-page-container-left > img {
    width: 90%;
    border-radius: 5%;
    max-height: 500px;
}

@media only screen and (max-width: 980px){

    .details-page-container {
        flex-direction: column;
    }

    .details-page-container-left {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }

    .details-page-container-left > img {
        width: 90%;
    }

    .details-page-details-images > img {
        margin-right: 1px;
        width: 80px;
    }

    .details-page-details-section {
        align-self: flex-start;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .details-page-details-description {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .details-page-container-right {
        margin: 2rem;
        width: 90%;
    }

    .details-page-time-nights-container {
        flex-direction: column;
    }

    .details-page-button {
        margin-right: 2rem;
        width: 180px;
    }
}

@media only screen and (max-width: 600px){

    .details-page-container {
        flex-direction: column;
    }

    .details-page-container-left {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }

    .details-page-container-left > img {
        width: 90%;
    }

    .details-page-details-images > img {
        margin-right: 1px;
        width: 80px;
    }

    .details-page-details-section {
        align-self: flex-start;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .details-page-details-description {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .details-page-container-right {
        margin: 2rem;
        width: 90%;
    }

    .details-page-time-nights-container {
        flex-direction: column;
    }

    .details-page-button {
        margin-right: 2rem;
        width: 180px;
    }
}