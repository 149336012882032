.earning-header{
    padding: 20px;
}
.earning-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}
.total-text {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    line-height: 24px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}
.earning-amount {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    line-height: 48px;
    margin-bottom: 20px;
    color : #363636;
    font-family: 'Poppins', sans-serif;
}
.chart-image {
    width: 100%;
    height: 300px;
}
.summary-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    font-family: 'Poppins', sans-serif;
}
.earning-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
