.bookingDetail-header {
  padding: 20px;
}
.bookingDetail-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.bookingDetail-body-left {
  display: flex;
  width: 50%;
  justify-content: center;
  flex-direction: column;
}
.bookingDetail-body-left h3 {
  margin-bottom: 10px;
  font-weight: 700;
  color: #5f5f5f;
  line-height: 30px;
  font-size: 20px;
  font-family: Open Sans;
}
.bookingDetail-card {
  margin-top: 20px;
  height: 80px;
  border-radius: 19px;
  background-color: white;
  padding: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 10px 0px #f5f5f5;
}
.bookingDetail-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bookingDetail-card-header-left {
  display: flex;
  align-items: center;
}
.bookingDetail-card-header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #ffd8ab;
  padding: 10px 10px;
  margin-right: 10px;
  border-radius: 10px;
}
.light-blue {
  background-color: #AFC6EB;
}
.bookingDetail-card-header-right-item {
  display: flex;
  flex-direction: column;
}
.bookingDetail-card-header-right-sub {
  display: flex;
  align-items: center;
}
.bookingDetail-card-header-right-item-text {
  color: #5f5f5f;
  font-weight: 700; 
  font-size: 10px;
  font-family: Open Sans;
}
.bookingDetail-card-header-right-item  p {
    color:  #5f5f5f;
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
}


.bookingDetail-card-header-right-item h2 {
  margin-right: 10px;
}

.bookingDetail-userImage {
  width: 20px;
  height: 20px;
  border-radius: 10%;
}
.bookingDetail-body-left img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
.bookingDetail-userName {
  color: #5f5f5f;
  font-weight: 700;
  font-size: 18.15px;
  line-height: 27.22px;
  text-align: center;
  font-family: Open Sans;
}
.bookingDetail-portName {
  color: #929292;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 21px;
  font-size: 13.61px;
}
.bookingDetail-body-right {
  flex-direction: column;
}
.bookingDetail-calendar {
  width: 100%;
  height: 100%;
  border-radius: 19px;
}

@media (max-width: 1024px){
 .bookingDetail-body {
    flex-direction: column;
 }   
 .bookingDetail-body-left {
    width: 100%;
    margin-bottom: 20px;
 }
}
