.container {
  display: flex;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.65);
}
.main-container {
  display: flex;
  gap: 10px;
}
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: sticky;
  top: 0;
  padding: 7px;
  overflow-y: auto;
  background-color: rgba(30, 130, 230, 0.06);
}
.siderbar-container{
 display: flex;
  justify-content: center;
  padding: 10px;
}
.sidebar-logo {
    width: 90%;
    object-fit: cover;
}
.menu-container{
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    margin-top: 30px;
    padding-bottom: 5px;
}
.menu-logo {
    width: 24px;
    height: 24px;
}
.menu-item {
    cursor: pointer;
}
.line {
  height: 1px; 
  width: 89%;
  margin-top: 300px; 
  background-color: #D1D5DB; 
}
.main_content{
  /* padding: 10px; */
  width: 100%;

}
.header{
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-heading {
  font-size: 18px;
  font-weight: 400;
  color: #7F7F7F;
  line-height: 26px;
}
.header-right{
  display: flex;
  gap: 10px;
}
.menu-icon {
  width: 48px;
  height: 48px;
}
.header-title {
  font-size: 28px;
  font-weight: 600;
  color: #1D191C;
  line-height: 42px;
}
.content {
  padding: 20px;
  /* width: 100%; */
  /* background-color: red; */
}

@media screen and (max-width: 768px) {
  .main-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .sidebar {
    display: none;
    padding: 5px; 
  }
  .siderbar-container{
    width: 100%;
  }
  .header {
    align-self: stretch;
  }
  .menu-bar{
    width: 20px;
    margin-top: 20px;
    margin-left: 20px;
  }
}
