.locations-container {
    margin-top: 2rem;
}

.locations-custom-shadow {
    box-shadow: 10px 10px 52px -14px rgba(0,0,0,0.42) !important;
    -webkit-box-shadow: 10px 10px 52px -14px rgba(0,0,0,0.42) !important;
    -moz-box-shadow: 10px 10px 52px -14px rgba(0,0,0,0.42) !important;
}

.search-results-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.rate-container {
    display: flex;
    align-items: flex-end;
    font-size: 20px;
}

.locations-rate-font-spec {

}

@media screen and (max-width:1220px) {
    .rate-container {
        font-size: 14px;
    }
    .locations-rate-font-spec {
        font-size: 16px;
    }
}


@media screen and (max-width: 980px) {

    .locations-container > .search-results-container > .banner-card-container > .banner-card {
        flex-direction: column !important;
        width: 85% !important
    }

}

@media screen and (max-width: 600px) {

    .locations-container > .search-results-container > .banner-card-container > .banner-card {
        flex-direction: column !important;
        /* width: 350px !important */
    }

}
