.port-container {
  padding: 20px;
  width: 100%;
}
.port-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-port {
  width: 30%;
}
.port-banner {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-top: 20px;
  border-radius: 16px;
}
.port-Description {
  margin-top: 20px;
}
.port-desc-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  font-family: Open Sans;
}
.custom-margin {
  margin-top: 20px;
}
.port-desc {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-top: 10px;
}
.earning-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
}
.earning-banner {
  width: 100%;
  height: 244px;
  border-radius: 35px;
  object-fit: cover;
}
.coordinates {
  margin-top: 20px;
}
.coordinates-container {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}
.coordinates-title {
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  font-family: Open Sans;
}
.earning-icon {
  width: 63px;
  height: 63px;
}
.earning-container{
    display: flex;
    /* margin-top: 20px; */
    /* align-items: center; */
}
.earning-mainContainer  {
    display: flex;
    gap: 20px;
    margin-top: 80px;
    justify-content: center;
}
.earning-text{
    font-size: 60px;
    font-weight: 700;
    line-height: 90px;
    font-family: Open Sans;
    text-align: center;
}
.earning-title {
    font-size: 35px;
    font-weight: 300;
    line-height: 53px;
    font-family: Open Sans;
    text-align: center;
}

@media screen and (max-width: 1024px) {
  .port-banner {
    height: 200px;
  }
  .port-desc-title {
    font-size: 18px;
  }
  .port-desc {
    font-size: 16px;
  }
  .earning-banner {
    height: 200px;
  }
  .coordinates-title {
    font-size: 16px;
  }
  .earning-text {
    font-size: 40px;
  }
  .earning-title {
    font-size: 25px;
  }
}

