.alpha-financing-finance_step_two_buttons_top_view {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.6rem;
}

.alpha-financing-finance_step_two_back_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16.1rem;
  height: 4.8rem;
  background: #D2D2D2;
  border-radius: 1rem;
  cursor: pointer;
  margin-right: 2rem;
}

.alpha-financing-finance_step_two_back_button h2 {
  font-family: lato;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  color: #717171;
}


.alpha-financing-finance_step_two_next_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16.1rem;
  height: 4.8rem;
  background: #F18805;
  border-radius: 1rem;
  cursor: pointer;
}

.alpha-financing-finance_step_two_next_button h2 {
  font-family: lato;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  color: #FFFFFF;
}

@media screen and (max-width: 500px) {
  .alpha-financing-finance_step_two_back_button h2 {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  .alpha-financing-finance_step_two_next_button h2 {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}