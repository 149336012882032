.about-page-about-container {
    height: 88rem;
}

.about-page-about-container-content {
    width: 55%;
    margin-left: 8rem;
}

.about-page-about-container-content > h1 {
    width: 30px;
    margin-bottom: 4rem;
    font-family: Open Sans;
    font-size: 30px;
    margin-top: 5rem;
    font-weight: 700;
}

.about-page-about-container-form {
    align-self: flex-end;
}

.fix-rem {
    height: 4rem !important;
}

.about-page-about-container-content > p {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    width: 70%;
}

.about-page-about-container-content {

}

.about-page-about-container-form > h1 {
    margin-bottom: 2rem;
    font-family: Open Sans;
    font-size: 28px;
    font-weight: 700;
}

@media screen and (max-width: 980px) {
    
    .about-page-about-container > .about-page-about-container-content > .contact-us-container > .contact-card-container {
        align-items: flex-start;
    }

    .about-page-about-container {
        height: unset;
        flex-direction: column;
    }

    .about-page-about-container-content {
        width: unset;
        margin-left: unset;
    }

    .about-page-about-container-content > p {
        width: unset;
    }

    .about-page-about-container-form {
        align-self: flex-start;
        width: 100%;
    }

    .about-page-about-container-form > .button-container {
        width: 90%;
    }

    .fix-rem {
        width: 90% !important;
    }
}

@media screen and (max-width: 600px) {
    
    .about-page-about-container {
        height: unset;
        flex-direction: column;
    }

    .about-page-about-container-content {
        width: unset;
        margin-left: unset;
    }

    .about-page-about-container-content > p {
        width: unset;
    }

    .about-page-about-container-form {
        align-self: flex-start;
    }

    .fix-rem {
        width: 30rem !important;
    }

    .fix-rem {
        width: 30rem !important;
    }
}