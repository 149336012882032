.alpha-finance_step-admin_top_view {
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;
}

.alpha_margin_right {
  margin-right: 5rem;

}

.alpha-financing-step_one_admin_inputs_top_view {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.alpha-financing-finance_step_one_admin_button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.6rem;
}

.alpha-financing-finance_step_one_admin_button div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34.3rem;
  height: 4.8rem;
  background: #F18805;
  border-radius: 10px;
  cursor: pointer;
}

.alpha-financing-finance_step_one_admin_button h2 {
  font-family: lato;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
}

@media screen and (max-width:530px) {
  .alpha-financing-finance_step_one_admin_button div {
    width: 25.3rem;
    height: 4rem;
  }

  .alpha-financing-finance_step_one_admin_button h2 {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}

@media screen and (max-width:400px) {
  .alpha-financing-finance_step_one_admin_button {
    justify-content: flex-start;
  }
}

@media screen and (max-width:360px) {
  .alpha-financing-finance_step_one_admin_button div {
    width: 20.3rem;
    height: 4rem;
  }
}